import * as React from "react"
import { graphql } from "gatsby"

import Default from "../templates/Default"

const NotFoundPage = ({ data }) => {
  return (
    <Default data={data} />
  )
}

export default NotFoundPage

export const query = graphql`
  query errorPage {
    wpPage(id: {eq: "cG9zdDo2Nzc="}) {
      ...SEO
      page {
        ...FlexibleContent
      }
    }
  }
`
